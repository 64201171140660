export default {
    getUserAuthState(state) {
        return state.loggedIn;
    },
    getAdminAuthState(state) {
        return state.loggedInAdmin;
    },
    getRegisteredState(state) {
        return state.fullyRegistered
    },
    isAuthenticated(state) {
        return !!state.token;
    },
    getUser(state) {
        return state.user;
    },
    getToken(state) {
        return state.token;
    },
    getCompliance(state) {
        return state.complianceAccepted;
    }
}
