export default {

    setUser(state, payload) {
        state.user.address = payload.address;
        state.user.city = payload.city,
        state.user.created_at = payload.created_at,
        state.user.email = payload.email,
        state.user.email_verified_at = payload.email_verified_at,
        state.user.fullname = payload.fullname,
        state.user.id = payload.id,
        state.user.phone = payload.phone,
        state.user.postcode = payload.postcode,
        state.user.role_id = payload.role_id,
        state.user.updated_at = payload.updated_at
    },

    setToken(state, payload) {
        state.token = payload.token;
    },

    setAdmin(state) {
        state.loggedInAdmin = true
    },

    // helper functions

    loginSuccess(state, auth) {
        state.loggedIn = true;
        state.token = auth.token;
        state.user = auth.user;
    },
    loginFailure(state) {
        state.loggedIn = false;
        state.token = null;
        state.user = null;
    },
    logout(state) {
        state.loggedIn = false;
        state.token = null;
        state.user = null;
    },
    registerSuccess(state, auth) {
        state.loggedIn = true;
        state.token = auth.token;
        state.user = auth.user;
    },
    registerFailure(state) {
        state.loggedIn = false;
    },
    registrationUnfinished(state) {
        state.fullyRegistered = false;
    },
    registrationFinished(state) {
        state.fullyRegistered = true;
    },
    setCompliance(state, status) {
        state.complianceAccepted = status;
    }
}
