export default {
    getOfferFilters(state) {
        return state.offerFilters;
    },
    getOfferEditState(state) {
        return state.offerEditController.offerEdit
    },
    getSelectedOffer(state) {
        return state.selectedOffer
    }
}