import { createRouter, createWebHistory } from 'vue-router'

import store from '../store/index.js'
import { routes } from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(function(to, from, next) {

  const modalBackdrop = document.getElementsByClassName("modal-backdrop")
  // console.log(modalBackdrop)
  if (modalBackdrop.length > 0) {
    modalBackdrop[0].remove()
  }

  // let nextUrl = '/'

  // if(from.name === 'Account' && !store.getters['auth/getRegisteredState']) {
  //   next('/admin/ucet');
  // } else
  if(to.meta.requiresAdminAuth && !store.getters['auth/getAdminAuthState']) {
    // nextUrl = '/prihlasenie'
    next('/prihlasenie')
  } else
  if(to.name === 'CreateOffer' && !store.getters['auth/getRegisteredState']) {
    // nextUrl = '/admin/ucet'
    next('/admin/ucet')
  } else
  if(to.meta.requiresAuth && !store.getters['auth/getUserAuthState']) {
    // nextUrl = 'prihlasenie'
    next('prihlasenie')
  } else if (to.meta.requiresUnauth && store.getters['auth/getUserAuthState']) {
    // nextUrl = '/'
    next('/')
  } else {
    // nextUrl = ''
    next()
  }

  generateMeta(to, from, next)
  // next(nextUrl);
  window.scrollTo(0, 0)
});

function generateMeta(to, from, next) {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag));
}

// let counter = 0
// let isOnAccount = false
//
// router.afterEach(function(from) {
//   store.dispatch('auth/checkUserDataIntegrity')
//
//   if (from.name !== 'Account' && !store.getters['auth/getRegisteredState'] && !isOnAccount && counter <=4) {
//     counter++
//     router.replace({ path: '/admin/ucet' })
//     isOnAccount = true
//   }
// });

export default router
