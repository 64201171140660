import {createStore} from "vuex";

import authModule from './auth/index.js'
import offerModule from './offer/index.js'

const store = createStore({
    modules: {
        auth: authModule,
        offer: offerModule
    }
});

export default store;