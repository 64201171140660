import authMutations from './mutations'
import authActions from './actions'
import authGetters from './getters'

// const auth = JSON.parse(localStorage.getItem('auth'));
// const initialState = auth
//     ? { status: { loggedIn: true }, auth }
//     : { status: { loggedIn: false }, auth: null };

export default {
    namespaced: true,
    // state: initialState,
    state() {
        return {
            loggedIn: false,
            loggedInAdmin: false,
            fullyRegistered: true,
            complianceAccepted: false,
            token: '',
            user: {
                address: null,
                city: null,
                created_at: null,
                email: null,
                email_verified_at: null,
                fullname: null,
                id: null,
                phone: null,
                postcode: null,
                role_id: null,
                updated_at: null
            }
        };
    },
    mutations: authMutations,
    actions: authActions,
    getters: authGetters
};
