import offerMutations from './mutations'
import offerActions from './actions'
import offerGetters from './getters'

export default {
    namespaced: true,
    state() {
        return {
            offers: [],
            offerEditController: {
                offerEdit: false,
                cancelEdit: false,
            },
            favoriteOffers: [],
            sliderSettings: {
                value: [0, 2000000],
                format: {
                    suffix: '€',
                    decimals: 0,
                    thousand: '.',
                },
                tooltips: true,
                min: 0,
                max: 2000000,
                step: 50000,
            },
            offerFilters: {
                priceRange: [0, 2000000],
                category: {
                    sale: true,
                    rent: true,
                },
                offerType: {
                    house: true,
                    apartment: true,
                    estate: true,
                    other: true,
                }
            },
            selectedOffer: {
                id: null,
            },
        };
    },
    mutations: offerMutations,
    actions: offerActions,
    getters: offerGetters
};